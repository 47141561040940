export default {
  center: () => import("A:/Solutions/NewVision/newvision.ge/layouts/center.vue"),
  default: () => import("A:/Solutions/NewVision/newvision.ge/layouts/default.vue"),
  "georgian-law-review-menu": () => import("A:/Solutions/NewVision/newvision.ge/layouts/georgian-law-review/georgian-law-review-menu.vue"),
  "georgian-law-review-submenu": () => import("A:/Solutions/NewVision/newvision.ge/layouts/georgian-law-review/georgian-law-review-submenu.vue"),
  "georgian-law-review": () => import("A:/Solutions/NewVision/newvision.ge/layouts/georgian-law-review/georgian-law-review.vue"),
  "international-cooperation": () => import("A:/Solutions/NewVision/newvision.ge/layouts/international-cooperation.vue"),
  "legal-aid": () => import("A:/Solutions/NewVision/newvision.ge/layouts/legal-aid.vue"),
  "legal-etchis": () => import("A:/Solutions/NewVision/newvision.ge/layouts/legal-etchis.vue"),
  olympiad: () => import("A:/Solutions/NewVision/newvision.ge/layouts/olympiad.vue"),
  page: () => import("A:/Solutions/NewVision/newvision.ge/layouts/page.vue"),
  partnership: () => import("A:/Solutions/NewVision/newvision.ge/layouts/partnership.vue"),
  policies: () => import("A:/Solutions/NewVision/newvision.ge/layouts/policies.vue"),
  project: () => import("A:/Solutions/NewVision/newvision.ge/layouts/project.vue"),
  "research-project": () => import("A:/Solutions/NewVision/newvision.ge/layouts/research-project.vue"),
  research: () => import("A:/Solutions/NewVision/newvision.ge/layouts/research.vue"),
  school: () => import("A:/Solutions/NewVision/newvision.ge/layouts/school.vue")
}